import React, { Component } from "react";
import UnderConstruction from "./UnderConstruction";
// import Home from "./Home";

export default class App extends Component {
  render() {
    return <UnderConstruction />;
    // return <Home />;
  }
}
